<template>
    <SideBar />
    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 class="page-title">
                                قائمة العملاء
                            </h2>
                        </div>
                        <!-- Page title actions -->
                        <div class="col-auto ms-auto d-print-none">
                            <div  v-if="role == 1 || role == 3" class="btn-list">
                                <router-link to="/client/add/" class="btn btn-primary d-sm-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    إضافة عميل جديد
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">

                    <div class="col-12">
                        <div class="card">
                            <SearchBar :dataUrl=dataUrl :legends=true />
                            <!-- search -->
                            <div v-if="clients.length" class="table-responsive">
                                <table
                                    class="table card-table table-vcenter text-nowrap datatable table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th class="w-1">#</th>
                                            <th>اسم العميل
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-sm text-dark icon-thick" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 15 12 9 18 15" />
                                                </svg>
                                            </th>
                                            <th>رقم الجواز</th>
                                           <th>المهنة</th>
                                           <th>المكتب</th>
                                           <th>تاريخ الفحص</th>
                                            <th>تاريخ الانشاء</th>
                                            <th>ملاحظة</th>
                                            <th class="w-1"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="client in clients" :key="client.id">
                                            <td
                                                @click="$router.push({ name: 'detail_client', params: { id: client.id } })">
                                                <span v-if="client.name" class="text-muted">
                                                    <small>

                                                        <span v-if="client.status==0" class="badge rounded-pill bg-danger">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><path d="M10 10l4 4m0 -4l-4 4" /></svg>
                                                        </span>
                                                        <span v-else-if="client.moderation_status==2" class="badge rounded-pill bg-warning">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><line x1="9" y1="12" x2="15" y2="12" /></svg>
                                                        </span>
                                                        <span v-else-if="client.has_active_internal_note==1" class="badge rounded-pill bg-info">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><line x1="12" y1="8" x2="12" y2="12" /><line x1="12" y1="16" x2="12.01" y2="16" /></svg>
                                                        </span>

                                                        <span v-else class="badge rounded-pill bg-green">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><path d="M9 12l2 2l4 -4" /></svg>
                                                        </span>
                                                    </small>
                                                </span>
                                            </td>
                                            <td
                                                @click="$router.push({ name: 'detail_client', params: { id: client.id } })">
                                                <span v-if="client.name" class="avatar avatar-sm mx-2">  
                                                    <img :src="client.photo" />

                                                </span>
                                                {{ client.name|| "غير موجود" }}
                                            </td>
                                            <td >
                                                {{ client.passport_no }}
                                            </td>
                                            <td v-if="client.name">
                                                {{ client.occupation }}
                                            </td>
                                            <td v-if="client.name">
                                                {{ client.office_name }}
                                            </td>
                                            <td v-if="client.name">{{ client.last_report_date }}</td>
                                            <td v-if="client.name">{{ get_date(client.created) }}</td>
                                            <td v-if="client.name">{{ client.notes}}</td>
                                            <td v-if="client.name">
                                                <div  class="btn-list flex-nowrap">

                                                <button class="btn d-sm-inline-block"
                                                    @click="$router.push({ name: 'detail_client', params: { id: client.id } })">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-arrow-left" width="24"
                                                        height="24" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                                        <line x1="5" y1="12" x2="11" y2="18"></line>
                                                        <line x1="5" y1="12" x2="11" y2="6"></line>
                                                    </svg>
                                                    <span class="mx-1">عرض</span>

                                                </button>
                                                <button v-if="role == 1 || role == 3"
                                                    @click="$router.push({ name: 'edit_client', params: { id: client.id } })"
                                                    class="btn d-sm-inline-block">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-edit" width="24" height="24"
                                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path
                                                            d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                        </path>
                                                        <path
                                                            d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                        </path>
                                                        <path d="M16 5l3 3"></path>
                                                    </svg>
                                                    <span class="mx-1">تعديل</span>

                                                </button>

                                                <!-- <button  v-if="role == 1"
                                                    @click="deleteClient(client.id)"

                                                    class="btn btn-danger d-sm-inline-block">
                                                    <span class="mx-1">حذف</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="4" y1="7" x2="20" y2="7" /><line x1="10" y1="11" x2="10" y2="17" /><line x1="14" y1="11" x2="14" y2="17" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                                </button> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- pagination -->
                            <PaginationBar :pagination_data=pagination_data />
                        </div>
                    </div>

                </div>
            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import PaginationBar from "@/components/PaginationBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import SearchBar from "@/components/SearchBar.vue";
import  { get_date } from '@/script.js'

export default {
    setup(){
      return { get_date }
    },
    components: {
        SideBar,
        PaginationBar,
        FooterBar,
        SearchBar
    },
    computed: {
        role() {
            return this.$store.getters.getUser.role;
             }
        },
    data() {
        return {
            clients: [],
            msg: '',

            dataUrl: '/api/client/',

            pagination_data: '',
            urlArgs: '',

        }
    },

    methods: {



    async deleteClient(clientId) {
        if (confirm("حذف العميل؟") != true) return false;
        const url = this.dataUrl + clientId; // Adjust this URL according to your API structure
        
        await this.axios.delete(url).then(() => {
                //Perform Success Action
                if (this.$store.getters.getHttpError) {
                    let error_obj = this.$store.getters.getHttpError
                    this.msg = error_obj.message;
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }

                this.clients = this.clients.filter(client => client.id !== clientId);
            });
        
        },
        
        async getData(url) {

            const response = await this.axios.get(url);
            // set the data returned as tasks
            if (response) {
                this.pagination_data = response.data;
                this.clients = response.data.results;


                const urlParams = new URL(response.request.responseURL);
                this.urlArgs = urlParams.search
                

            }

            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;



        },

    },
    async created() {
        this.urlArgs = window.location.search;
        this.getData(this.dataUrl + this.urlArgs);
    },

    // watch: {
    //     '$route.query': function (query) {
    //         if (Object.keys(query).length === 0) {
    //             this.getData(this.dataUrl);
    //         }
    //     }
    // }

};

</script>