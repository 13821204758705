import { createApp } from 'vue'
import App from './App.vue'

import VueApexCharts from "vue3-apexcharts";
import axios from 'axios'
import router from './router'
import store from './store'
import i18n from './i18n'
import Notifications, { notify } from '@kyvg/vue3-notification'

import VueClickAway from "vue3-click-away";

axios.defaults.headers.common['Accept-Language'] = `AR`;

if (store.state.token) {
    axios.defaults.headers.common['Authorization'] = `token ${store.state.token}`;
}


axios.interceptors.request.use(
    config => {
        store.dispatch('setLoading', true);
        store.dispatch('http_error', {});
        return config;

    },
    error => {
        store.dispatch('setLoading', false);// In case of an error, make sure to hide the loader
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(response => {
    // Clear the flag when the response is received
    store.dispatch('setLoading', false);

    return response;
}, error => {
    store.dispatch('setLoading', false);
    if (error.response.status === 401) {
        axios.defaults.headers.common['Authorization'] = "";
        store.dispatch('logout');
        router.push('/login/');

    } else if (error.response.status === 403) {
        store.dispatch('http_error', { error });
        notify({

            text: "غير مصرح لك!",
            type: 'error',

        });
        axios.defaults.headers.common['Authorization'] = "";
        store.dispatch('logout');
        router.push('/login/');
    } else {
        store.dispatch('http_error', { error });
        let msg = "حدث خطأ ما!";
        if (error.response?.data?.detail) msg = error.response.data.detail;

        notify({

            text: msg,
            type: 'error',

        });
    }
});

axios.defaults.baseURL = "https://api.sabertests.com";
if (process.env.NODE_ENV == 'development') axios.defaults.baseURL = "http://localhost:8000";


const app = createApp(App).use(i18n).use(store).use(router).use(Notifications).use(VueClickAway).use(VueApexCharts);
app.config.globalProperties.axios = axios;


app.mount('#app');

