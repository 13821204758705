<template>
    <SideBar />

    <div class="page">
        <div class="page-wrapper">
            <div class="container-xl">
                <!-- Page title -->
                <div class="page-header d-print-none">
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Page pre-title -->
                            <div class="page-pretitle">
                            </div>
                            <h2 v-if="form.id" class="page-title">
                                تعديل مستخدم
                            </h2>
                            <h2 v-else class="page-title">
                                إضافة مستخدم جديد
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body">
                <div class="container-xl">
                    <div class="row row-cards">
                        <div class="col-12 col-lg-6">
                            <form v-on:submit.prevent="submitForm" class="card card-md">
                                <div v-if="form_errors" class="invalid-feedback">{{ form_errors.non_field_errors }}
                                </div>
                                <div class="card-header">
                                    <h4 class="card-title">بيانات المستخدم</h4>
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-12">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">اسم المستخدم</label>
                                                        <input class="form-control"
                                                            :class="form_errors.username ? 'is-invalid' : ''"
                                                            v-model="form.username" />
                                                        <div class="invalid-feedback">{{ form_errors.username?.[0] }}
                                                        </div>
                                                    </div>
                                                    <div  class="col-12 mb-3">
                                                        <label class="form-label">كلمة المرور</label>

                                                        <input class="form-control"
                                                            :class="form_errors.password ? 'is-invalid' : ''"
                                                            v-model="form.password" />
                                                        <div class="invalid-feedback">{{ form_errors.password?.[0] }}
                                                        </div>
                                                        <small class="form-hint">
                                                            يستحسن استخدام كلمة مرور معقدة وصعبة التخمين.
                                                        </small>
                                                        <label class="form-label"> تأكيد كلمة المرور</label>

                                                        <input class="form-control"
                                                            :class="form_errors.password2 ? 'is-invalid' : ''"
                                                            v-model="form.password2" />
                                                        <div class="invalid-feedback">{{ form_errors.password2?.[0] }}
                                                        </div>
                                                       <hr/>
                                                    </div>
                                                    
                                                    

                                                    <div class="col-4 mb-3">
                                                        <label class="form-label">الاسم الأول</label>
                                                        <input class="form-control"
                                                            :class="form_errors.first_name ? 'is-invalid' : ''"
                                                            v-model="form.first_name" />
                                                        <div class="invalid-feedback">{{ form_errors.first_name?.[0] }}
                                                        </div>
                                                    </div>
                                                    <div class="col-4 mb-3">
                                                        <label class="form-label">الاسم الأخير</label>
                                                        <input class="form-control"
                                                            :class="form_errors.last_name ? 'is-invalid' : ''"
                                                            v-model="form.last_name" />
                                                        <div class="invalid-feedback">{{ form_errors.last_name?.[0] }}
                                                        </div>
                                                    </div>
                                                    <div class="col-4 col-lg-4 mb-3">
                                                        <label class="form-label"> الدور</label>
                                                        <select class="form-control"
                                                            :class="form_errors.role ? 'is-invalid' : ''"
                                                            v-model="form.role">
                                                            <option value="3" selected>RECEPTIONIST</option>
                                                            <option value="4">LAB</option>
                                                            <option value="5">DOCTOR</option>
                                                            <option value="1">ADMIN</option>

                                                        </select>
                                                        <div class="invalid-feedback">{{ form_errors.role?.[0]
                                                        }}</div>
                                                    </div>

                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">رقم الهاتف</label>
                                                        <input class="form-control"
                                                            :class="form_errors.mobile ? 'is-invalid' : ''"
                                                            v-model="form.mobile" />
                                                        <div class="invalid-feedback">{{ form_errors.mobile?.[0] }}
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mb-3">
                                                        <label class="form-label">الايميل</label>
                                                        <input class="form-control"
                                                            :class="form_errors.email ? 'is-invalid' : ''"
                                                            v-model="form.email" />
                                                        <div class="invalid-feedback">{{ form_errors.email?.[0] }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 mb-3">
                                                        <label class="form-label"> نشط؟</label>
                                                        <input type="checkbox" v-model="form.is_active" checked>
                                                        <div class="invalid-feedback">{{ form_errors.is_active?.[0]
                                                        }}</div>
                                                    </div>
                                            </fieldset>

                                        </div>

                                    </div>


                                </div>

                                <div class="card-footer text-end">
                                    <div class="d-flex order-reversed">
                                        <a href="#" onclick="history.back()" class="btn">إلغاء</a>
                                        <button :disabled="!isFormChanged" type="submit" class="btn btn-primary ms-auto">حفظ</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <FooterBar />
            <!-- footer -->
        </div>
    </div>
</template>
  
<script>
import FooterBar from '@/components/FooterBar.vue';
import SideBar from '@/components/SideBar.vue';

export default {
    components: {
        FooterBar,
        SideBar
    },
    computed: {
        isFormChanged() {
        return JSON.stringify(this.form) !== JSON.stringify(this.initialForm);
        }
    },
    data() {
        return {
            form: {
                id: '',
                username: '',
                last_name: '',
                first_name: '',
                role:2,
                mobile: '',
                password: '',
                password2: '',
                email: '',
                is_active:true,
                moderation_status: ''

            },

            msg: '',
            form_errors: [],
            initialForm: null,

        }
    },

    methods: {

        async submitForm() {
            let post_method = this.axios.post;
            let url = `/api/user/`
            if (this.form.id) {
                post_method = this.axios.patch;
                url = `/api/user/${this.form.id}/`
            }

            await post_method(url, this.form).then(() => {
                //Perform Success Action
                if (this.$store.getters.getHttpError) {
                    let error_obj = this.$store.getters.getHttpError
                    this.msg = error_obj.message;
                    if (error_obj.response.data) this.form_errors = error_obj.response.data;

                }

                else this.$router.replace('/admin/users/');
            });


        },
        async getData() {

            // set the data returned as tasks
            if (this.form.id) {
                const response = await this.axios.get(`/api/user/${this.form.id}/`);
                // set the data returned as tasks
                if (response) this.form = response.data;
            }
            if (this.$store.getters.getHttpError) this.msg = this.$store.getters.getHttpError.message;

        },
    },
    async created() {
        this.form.id = this.$route.params.id;
        await this.getData();
        this.initialForm = JSON.parse(JSON.stringify(this.form));



    },
}
</script>
  